import style from './ChallengeButton.module.css'
export const ChallengeButton = ({children, variant, onMouseEnter, isNew, onClick}) => {
    return (
        <div className={style.container}>
            <button
                className={`${style.button} ${variant === 'spring' ? style.spring : style.autumn}`}
                onMouseEnter={onMouseEnter}
                onClick={onClick}
            >
                {children}
            </button>
            {isNew && (<span className={style.newTick}>Nouveau</span>)}
        </div>
    )
}