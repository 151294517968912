import React from 'react';
import style from './Modal.module.css'
export const Modal = ({open, width, onClose}) => {
    return (
        <>{open &&
            <div className={style.root}>
                <div className={`${style.container} ${style[width]}`}>
                    <div className={style.closeTextContainer}>
                        <button className={style.closeTextButton} onClick={onClose}>Fermer</button>
                    </div>
                    <div className={style.blockText}>
                            <p>L'application de l'automne n'est pas encore finalisée.</p>
                            <p>Si vous avez déjà réservé une place pour votre classe, vous recevrez un mail avant les vacances pour découvrir l'application et ses ressources.</p>
                            <p>Si vous n'êtes pas encore inscrit(e), merci de le faire en cliquant <a className={style.link} href="https://zfrmz.eu/rDlCySmN3vaM36XjQBLN">ici</a>.</p>
                            <p>Nous vous souhaitons une belle opération de vente !</p>
                    </div>

                </div>
            </div>
        }</>
    )
}